@import "./../../../app/mixins.scss";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(192, 192, 192, 0.64);
  visibility: hidden;
  opacity: 0;
  transition: visibility $transition-delay, opacity $transition-delay linear;

  &.open {
    visibility: visible;
    opacity: 1;
  }
}

.modalScroll {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 20px 0;
}

.modalContent {
  border: 2px solid transparent;
  border-radius: 2px;
  background-color: $color-white;
  padding: 25px 20px;
  box-shadow: $dropdown-shadow;
  position: relative;
  margin: auto;

  @include primary-border-color();
}

.modalHeader {
  padding: 0;
}

button.btnClose {
  $size: 21px;

  position: absolute !important;
  right: 20px;
  top: 12px;
  width: $size;
  height: $size;

  &:hover {
    opacity: 0.8;
  }

  .iconClose {
    width: $size;
    height: $size;
  }
}

.defaultCaption {
  text-align: center;
  color: $color-blue-dark;
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  padding-top: 15px;
}
