@use 'sass:math';

.root {
  position: relative;

  :global {
    .react-datepicker__input-container {
      display: block;
    }

    .react-datepicker__close-icon {
      display: none;
    }
  }
}

.icon {
  $size: 18px;

  position: absolute;
  top: 50%;
  right: 4px;
  height: $size;
  width: $size;
  margin-top: math.div(-$size, 2);
  pointer-events: none;
  fill: #333;
}

:global {
  .react-datepicker__year-dropdown-container--select,
  .react-datepicker__month-dropdown-container--select,
  .react-datepicker__year-dropdown-container--scroll,
  .react-datepicker__month-dropdown-container--scroll {
    margin-top: 5px;
  }

  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow {
    margin-left: 30px;
    top: 5px;
  }
}
